
.sidebar {

  height: 100%;
  padding: 0 10px;
 background-color: #fcfcfc;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  color: #fff;
}

.sidebar a {
  padding: 16px;
  text-decoration: none;
  color: #fff;
  display: block;
}

.sidebar a:hover {
  background-color: #555;
}


.logo {
 
  top: 0;
  left: 0;
  padding-left: 10px;
  padding-top: 20px;
  height: 20%;
}


.top {
    color: #1DA1F2;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-right: 20px;
    font-size: 20px;
    
    padding-top: 30px;
  }

  
 .btn:hover {
    background-color: rgb(62, 105, 225);
  }

  .custom-button:hover {
    color: #FFF;
  }


  .uicon {
    
    padding-top: 350px;
  }

  .top {
    color: #1DA1F2;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-right: 20px;
    font-size: 20px;
    
  }


  .Header {
    transform: translate(10%, -220%);

  }


  .buttons {
    height: 60%;
    
  }

  .name {
    display: flex;
    justify-content: center;
  }
  
 

.hovered-button{
    border: none;
    background-color: #ffff;
}

.setb {
    border: none;
    background-color: #ffff;
}

.set{
  padding-top: 40px;
}

.hometext {

  color: #555;
  padding-top: 5px;
}

.hometext.hovered{
  color: #1DA1F2;
}

.settings{
 
  color: #555;
  padding-top: 7px;
}

.settings.hovered {
  color: #1DA1F2; 
}



.logb {
    border: none;
    background-color: #ffff;
    height: 20%;
}

.logout {
  color: #000000;

 }

 .logout.hovered {
  color: #1DA1F2; 
}

 .lb {
  height: 10%;
 }
   

table {
 width: 100%;
  border-collapse: collapse;
  position: center;
  overflow-x: auto;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 10px;
}


thead tr th {
  font-weight: 500;
  text-align: center;
  background-color: #1DA1F2; 
  color : #FFFFFF;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 0 rgba(0, 0, 0, 0.1);
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
}

.history{
padding-top: 80px;
}

.txt-history{
  
  color: #1DA1F2;
  font-size: 24px;
  font-weight: 600;
}

.FileUpload{
  padding-left: 90px;
  background-color: #F4F6F9;
}

.title{
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.text{
  font-size: 16px;
  font-weight: 600;
}

.paratext{
  font-size: 14px;
    font-weight: 400;
}

.cancelProgress{
  background-color: white;
  border: none;
  padding-right: 15px;
}

.progressDiv{
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.fileUploadName {
  display: flex;
  align-items: flex-start;
  width:85%;
}