.login  {
  font-family: 'Arial', sans-serif;
  background-color: #e9f2f7;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  
}

.login-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}



.loginLabel {
  display: block;
  margin-bottom: 8px;
}

.loginInput {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #1DA1F2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.loginStatus {
  text-align: center;
  margin-top: 16px;
  color: #d9534f;
}

.loginlogo{
  padding-bottom: 10px;
}

.loginPage{ 
  padding: 25px;
  text-align: center;
}



.carasol{
  height: 700px;
  width: 800px;
}




.popup{
  position: absolute;
  inset: 15px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    padding: 10px;
    width: 500px;
    margin: auto;
    height: 70px;
    background-color: #D92728;
}

.popbody{
  position: absolute;
    inset: initial;
    border: none;
    background: none;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 250px;
    margin: auto;
    height: 90px;
    top: 65px;
    right: 238px;
    overflow:none
}

.successpopbody{
  position: absolute;
  inset: initial;
  border: none;
  background: none;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 250px;
  margin: auto;
  height: 90px;
  top: 65px;
  right: 238px;
  overflow:none
}

.successpopup{
  position: absolute;
  inset: 15px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    padding: 10px;
    width: 500px;
    margin: auto;
    height: 70px;
    background-color:#6D9335;
}

.vThink{
  width: 200px;
  /* position: absolute;
  top: 105px;
  left: 79px; */
}

.nextbox{
  background-color: #FFFF;
  padding: 50px;
  border-radius: 15px;
}