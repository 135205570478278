h1,
p {
  font-family: Open Sans;
}

thead tr th {
  font-weight: 500;
  text-align: center;
  background-color: #1DA1F2; 
  color : #FFFFFF;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 0 rgba(0, 0, 0, 0.1);
  
}

tbody tr:nth-child(even) {
  background-color: #fafafa;
}

th,
td {
  padding: 8px;
  overflow-wrap: break-word;
  color: #484B4D;
}

.mail-btn {
  background-color: #1DA1F2; 
  color : #FFFFFF;
  border: none;
  height: 40px;
  width: 100px;
  margin: 0;
  border-radius: 5px;
  font-family: Open Sans;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
}

.mail-btn>img {
  height: 25px;
  width: 25px;
}

.pagination-bar {
  padding:20px;
  display: flex;
  justify-content: right;
}
.three-d-table {
  width: 50%;
  font-size: 12px;
}
.datepicker-body{
  height:14px;
padding: 12px;
gap:10px;
border: 1px solid #ccc;
border-radius: 5px;
justify-content : space-between;
box-shadow: 0px 0px 4px 0px rgba(156, 156, 156, 0.25);
background: #FFFFFF;
position: relative;
border: 1px solid #9C9C9C; 
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);


}

.icon{
  padding:5px;
}

.icon-filter{    
  
  width: 105px;
}
.c1 {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 padding-bottom: 15px;
 height: 60px;
}

.search {
  display: flex;
  width:100%;
  padding-top: 20px;
}
.wider-search-bar{
  color:#6D6F71;
  border-bottom-right-radius: 5px;
  border-radius: 5px;
  text-shadow:#9C9C9C ;
border: 1px solid #ccc;
font-size: 16px; 
padding-left: 20px;
width: 100%;
height: 35px;
border: 1px solid #9C9C9C; /* Add desired border styles */
border-radius: 5px; /* Add desired border radius */
box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); /* Add box-shadow for a subtle shadow effect */
outline: none; 
}

.attendance-header {
   color: #1DA1F2;
   align-items: left; 
   border:0px,0px,0.5px,0px;
   text-align: left;
   justify-content: space-between;
   border-inline-color: #B6B6B6;
   border-bottom: 0.5px;
   font-style: Open Sans;
   font-size : 10px;
 }
 .custom-button {
   background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
  align-items: center; 
  display: flex;
  justify-content: flex-start;
  align-items: center ;
 padding:0px;
 }

 .datepicker {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  padding-left:10px;
  gap:10px;
  padding: 10px;
  padding-top: 30px;
 }

 .filter {
  width: 15%;
  display: flex;
  justify-content: flex-end;
  padding-left:10px;
  gap:10px;
  padding-top: 10px;
 }
 
.container{
    padding-left: 100px;
}

 .searchbar
 {
  display: flex;
  justify-content: flex-start;
  align-items: center ;
  padding-bottom: 15px;
 }
.table1{
    padding-left: 100px;
}

.searchBar{
  width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.App {
  font-family: sans-serif;
  height: 100%;
    width: 100%;
    overflow: auto;
}


/* Snackbar CSS */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #1DA1F2;
  color: #fff;
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}


/* Modal component */
#modalComponent {
  position: absolute;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4) !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.modalContainer {
  width: max-content;
  margin: 30px 0;
  padding: 30px 60px;
  background-color: #444444;
  color: #fff
}

.action_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}