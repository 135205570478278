.side-slide-panel {
    position: fixed;
    top:0;
    right: -345px;
    height: 100%;
    color: #484B4D;
    background: #fff;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding-left: 15px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    overflow: auto;
}
  .side-slide-panel.open {
    transform: translateX(-300px);
    overflow: auto;
  }
  
  .content {
    padding: 0px;
    color: #484B4D;
  }
  
  button {
    margin-top: 10px;
  }
  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  }
  
  .checkbox-grid label {
    display: flex;
    align-items: center;
  }
  
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #1DA1F2;
    border-radius: 3px;
    outline: none;
    box-shadow: #484B4D;
  }
  
  input[type="checkbox"]:checked {
    background-image: url('Vector.png'); 
    background-size: 16px 14px;
  }

thead tr th {
    font-weight: 60;
    background-color: #1DA1F2;
    color: #FFFFFF;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 0 rgba(0, 0, 0, 0.1);
  }
  .custom {
    height: 35px;
    background-color: white;

    border: none;
    cursor: pointer;
    align-items: center;
    text-size-adjust: 10px;
    padding-left: 195px;
    display: justify;
    align-items: center;
    padding-bottom: 10px;
    position: right;
  }

  .customs-button{
    transform: scale(1.05);
  }
  
  .panel-header
  {
    width: 300px;
    color:#484B4D; 
    position: relative;
    margin-left: 0;
    text-align: justify
  }
  .underline {
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    
  }
  .topic {
    display:flex-start

  }
  
  .customs {
    border: none;
    gap:10px;
    padding-top: 10px;
    cursor: pointer;
    background-color:#FFFFFF;
  }


  .icon {
color: #fff;
  }
  .reset
  {
   border-radius: 4px;
    background-color:#FFFFFF;
    cursor: pointer;
    align-items: center;
    border: none;
  }


  .resetApply {
    padding-top: 10px;
    padding-right: 47px;
  }